<template>
  <v-app>
    <v-container
      fluid
      :style="backgroundColor"
      v-if="this.datosComunas.status != 200"
    >
      <v-layout
        no-gutters
        align-center
        justify-center
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          color: #fff;
        "
      >
        <v-flex xs6 sm6 md6 lg6 xl6 style="text-align: left">
          <!-- input form scanner -->

          <v-layout
            v-if="inicioProceso == true"
            no-gutters
            align-center
            justify-left
          >
            <v-flex
              xs5
              sm7
              lg3
              xl4
              style="text-align: center; border-radius: 4px"
            >
              <v-text-field
                outlined
                v-if="currentIngress.ingress_type_code === 2"
                hide-details
                single-line
                type="number"
                class="myStyle"
                v-model="itemQ"
                style="align-items: center; justify-content: center"
                label="CANTIDAD"
                prepend-icon="mdi-pound"
                :disabled="disableInput"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout class="mt-4">
            <v-flex
              v-if="inicioProceso"
              xs11
              sm10
              lg8
              xl4
              style="text-align: center; border-radius: 2px"
            >
              <div class="d-flex align-baseline justify-center text-center">
                <v-chip-group
                  v-model="selection"
                  active-class="deep-purple accent-4"
                >
                  <v-chip
                    class="mx-auto justify-center text-center"
                    v-for="size in sizes"
                    :key="size"
                    :value="size"
                    >{{ size }}</v-chip
                  >
                </v-chip-group>
              </div>
              <div class="d-flex align-baseline">
                <v-text-field
                  outlined
                  class="myStyle"
                  v-model="itemId"
                  style="align-items: baseline; justify-content: center"
                  label="CÓDIGO DE BARRAS"
                  prepend-icon="mdi-qrcode-scan"
                  :disabled="disableInput"
                  ref="input2"
                  autofocus
                  v-on:keyup.enter="
                    currentIngress.ingress_type_code === 1
                      ? scanItems()
                      : scanItemsMaterials()
                  "
                ></v-text-field>

                <v-btn
                  align-baseline
                  class="align-base"
                  icon
                  @click="
                    currentIngress.ingress_type_code === 1
                      ? scanItems()
                      : scanItemsMaterials()
                  "
                >
                  <v-icon color="#311eee" v-if="!disableInput" large
                    >mdi-send</v-icon
                  >
                </v-btn>
                <v-progress-circular
                  v-if="disableInput"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
              </div>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex
              class="grey--text text--darken-2"
              v-if="inicioProceso == true"
            >
              <img
                max-height="248"
                max-width="411"
                v-if="verMapa == true"
                :src="mapUrl"
              />
              <br />
              <h3>Tipo Recepción: {{ currentIngress.ingress_type_name }}</h3>
              <h4>
                Recepción Nº: {{ currentIngress.numRecepcion }}
                <v-icon
                  color="pink"
                  center
                  @click="openLink(currentIngress.numRecepcion)"
                  small
                  >mdi-content-copy</v-icon
                >
              </h4>
              <h4>Warehouse: {{ currentIngress.warehouse_name }}</h4>
              <h4>Cliente: {{ currentIngress.customer_name }}</h4>
              <h4>Inicio: {{ getCleaDate(currentIngress.date_creation) }}</h4>
              <h4>Usuario: {{ $store.state.user.data.email }}</h4>
              <h4>Estado: {{ currentIngress.name_status }}</h4>
              <h4>Items escaneados: {{ currentIngress.items.length }}</h4>
              <h4>
                Existe en Thor:
                {{ resumenItems(currentIngress.items).existeThor }}
              </h4>
              <v-layout
                v-if="resumenItems(currentIngress.items).NoExisteThor > 0"
                no-gutters
                align-left
                justify-left
                ><h3
                  style="
                    color: darkred;
                    background-color: #fffd00;
                    font-weight: bold;
                  "
                >
                  NO Existe en Thor:
                  {{ resumenItems(currentIngress.items).NoExisteThor }}
                </h3></v-layout
              >

              <h4 v-if="currentIngress.ingress_type_code === 2">
                Materiales totales: {{ calculaTotales }}
              </h4>
              <v-btn
                v-if="verPedidos == true"
                elevation="2"
                x-small
                color="pink"
                dark
                @click="refreshItems()"
                >Ver Items</v-btn
              >

              <br />
            </v-flex>
          </v-layout>
          <h1 style="font-size: 50px">{{ order_idScan }}</h1>
          <br />
          <br />

          <v-btn
            v-if="botonFin == true"
            color="primary"
            class="mr-4 mt-5"
            @click="dialog = true"
            :disabled="toggleDisableButtonFin()"
            :loading="sendingRequest"
          >
            Fin Recepción
          </v-btn>
          <br /><br />

          <v-col class="text-right">
            <v-btn
              v-if="botonFin == true"
              color="#9E0002"
              dark
              @click="dialogCancel = true"
              >Cancelar Recepción</v-btn
            >
          </v-col>

          <v-dialog persistent v-model="dialogIngressType" max-width="500px">
            <v-card>
              <v-card-title> Selecciona Tipo de Operación </v-card-title>
              <v-card-text>
                <v-select
                  v-model="currentIngressType"
                  :items="ingressTypesList"
                  label="Selecciona un tipo de operación de recepción"
                  item-text="name"
                  return-object
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="toggleDisableButtonIngressType()"
                  color="primary"
                  text
                  @click="
                    dialogIngressType = false;
                    dialogClientes = true;
                  "
                >
                  OK
                </v-btn>
                <v-btn color="primary" text @click="dialogIngressType = false">
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--  Dialogo de Recupero -->
          <v-dialog persistent v-model="dialogRecupero" max-width="500px">
            <v-card>
              <v-card-title class="headline">Información</v-card-title>
              <v-card-text
                >¡Se ha logrado recuperar la orden
                <strong>{{ currentIngress.numRecepcion }} </strong> para poder
                seguir con el proceso de recepción!
              </v-card-text>
              <v-card-actions>
                <v-btn color="red" text @click="dialogRecupero = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- FIN dialogo recupero -->

          <!--  Dialogo de Fin de Proceso -->
          <v-dialog persistent v-model="dialogFinRecepcion" max-width="500px">
            <v-card>
              <v-card-title class="headline">Información</v-card-title>
              <v-card-text
                >¡Se ha logrado cerrar correctamente la orden
                <strong>{{ currentIngress.numRecepcion }} </strong>!
              </v-card-text>
              <v-card-actions>
                <v-btn color="red" text @click="dialogFinRecepcion = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- FIN dialogo recupero -->

          <v-dialog persistent v-model="dialogClientes" max-width="500px">
            <v-card>
              <v-card-title>
                Selecciona Cliente, Rayo y Warehouse
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="selectedWarehouse"
                  :items="warehouses"
                  label="Selecciona un warehouse"
                  item-text="name"
                  return-object
                ></v-select>

                <v-select
                  v-model="selectedCustomer"
                  :items="clientes"
                  label="Selecciona un cliente"
                  item-text="name_customer"
                  return-object
                ></v-select>
                <v-select
                  v-model="selectedRayo"
                  :items="rayos"
                  label="Selecciona un/a rayo"
                  item-text="name_rayo"
                  return-object
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :disabled="toggleDisableIniciaIngress()"
                  color="primary"
                  text
                  @click="
                    dialogClientes = false;
                    inicioOperacion();
                  "
                >
                  OK
                </v-btn>
                <v-btn color="primary" text @click="dialogClientes = false">
                  Cancelar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" persistent max-width="560">
            <v-card>
              <v-card-title class="headline">CONFIRMACIÓN</v-card-title>
              <v-card-text
                >Si estás completamente segur@ de querer finalizar la recepción
                presiona CONFIRMAR. De lo contrario te invitamos a revisar la
                información cargada.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="green darken-1" text @click="finOperacion()"> -->
                <v-btn color="green darken-1" text @click="finOperacion()">
                  Confirmar
                </v-btn>
                <v-btn color="error" text @click="dialog = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogCancel" persistent max-width="560">
            <v-card>
              <v-card-title class="headline">CONFIRMACIÓN</v-card-title>
              <v-card-text
                >Segur@ deseas cancelar la orden de recepción
                <strong>{{ currentIngress.numRecepcion }} </strong>?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="green darken-1" text @click="finOperacion()"> -->
                <v-btn color="green darken-1" text @click="cancelRecepcion()">
                  Confirmar
                </v-btn>
                <v-btn color="error" text @click="dialogCancel = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogOK" persistent max-width="560">
            <v-card>
              <v-card-title class="headline">PROCESO FINALIZADO</v-card-title>
              <v-card-text>
                <v-alert outline type="success" value="true">
                  <h3>{{ this.mensajeCierre }}</h3>
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="cerramos()"> Cerrar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogPedidos" persistent>
            <v-card class="mx-auto">
              <v-subheader>LISTA DE ITEMS ESCANEADOS</v-subheader>

              <v-data-table
                v-if="currentIngress.ingress_type_code === 1"
                :headers="headers"
                :items="currentIngress.items"
                fixed-header
                hide-default-footer
                disable-pagination
                class="flex-table"
              >
                <template v-slot:[`item.item_warehouse_name`]="{}">
                  <td class="text-xs-center">
                    {{ currentIngress.warehouse_name }}
                  </td>
                </template>
                <template v-slot:[`item.order_id_task`]="{ item }">
                  <td class="text-xs-center">
                    {{ item.order_id_task }}
                    <v-icon
                      color="pink"
                      center
                      @click="openLink(item.order_id_task)"
                      small
                      >mdi-content-copy</v-icon
                    >
                  </td>
                </template>

                <template v-slot:[`item.item_actions`]="{ item }">
                  <v-icon
                    color="pink"
                    center
                    @click="deleteRemoteAndLocal(item.order_id_task)"
                    small
                    >mdi-delete</v-icon
                  >
                </template>

                <!-- <template v-slot:item.value="{ item }">
                 <td class="text-xs-center">
                    {{ item }}
                    <v-icon
                      color="pink"
                      center
                      @click="openLink(props)"
                      small
                      >mdi-content-copy</v-icon
                    >
                  </td> -->

                <!--          
             <td class="text-xs-center">
                    <v-img
                      max-height="150"
                      max-width="150"
                      :src="props.imageUrl"
                      style="width: 50px; height: 50px"
                    />
                  </td> -->
                <!-- </template> -->
              </v-data-table>

              <v-data-table
                v-else
                :headers="headersMaterials"
                :items="currentIngress.items"
                class="elevation-2"
              >
                <template v-slot:[`item.barcode`]="{ item }">
                  <td class="text-xs-center">
                    {{ item.barcode }}
                    <v-icon
                      color="pink"
                      center
                      @click="openLink(item.barcode)"
                      small
                      >mdi-content-copy</v-icon
                    >
                  </td>
                </template>

                <template v-slot:[`item.ts`]="{ item }">
                  <td class="text-xs-center">
                    {{ showDate(item.ts) }}
                  </td>
                </template>

                <template v-slot:[`item.item_actions`]="{ item }">
                  <v-icon
                    color="pink"
                    center
                    @click="deleteRemoteAndLocalMaterials(item.barcode)"
                    small
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="dialogPedidos = false">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--vue-clock /-->

          <v-overlay z-index="999999999" :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-flex>

        <v-divider vertical style="border-width: 4px !important"></v-divider>

        <v-flex xs12 sm12 md12 lg12 xl12 style="text-align: center">
          <v-layout no-gutters align-center justify-center>
            <v-flex xs4 sm4 lg4 xl4 style="text-align: center">
              <v-layout no-gutters align-center justify-center>
                <v-btn
                  v-if="inicioProceso == false"
                  @click="dialogIngressType = true"
                  style="
                    display: flex;
                    width: 40vh;
                    height: 30vh;
                    border: none;
                    color: black;
                    font-size: 20px;
                    cursor: pointer;
                    text-align: center;
                  "
                >
                  Iniciar Recepción
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-snackbar bottom v-model="snackMongo" :timeout="timeout">
            {{ snackText }}
          </v-snackbar>

          <!-- SNACKBAR copy ASIGNACION -->
          <v-snackbar top v-model="snackbarCopy" :timeout="timeoutCopy">
            ¡Copiado!
          </v-snackbar>
          <!-- FIN SNACKBAR copy ASIGNACION -->

          <!-- // data scanner -->

          <v-layout
            v-if="
              inicioProceso == true && currentIngress.ingress_type_code === 1
            "
            no-gutters
            align-center
            justify-center
          >
            <v-flex sm9 md9 shrink>
              <v-card
                class="pt-5 mx-auto my-12 xs7 sm7 lg4 xl4 justify-center text-center"
                max-width="80%"
              >
                <v-img
                  center
                  width="250"
                  class="mx-auto"
                  :src="scannedItemDetails.image_customer"
                ></v-img>
                <v-card-title class="mx-auto justify-center text-center">{{
                  scannedItemDetails.order_id_task
                }}</v-card-title>
                <v-card-title class="mx-auto justify-center text-center">
                  {{ scannedItemDetails.name_customer }}
                </v-card-title>

                <v-divider class="mx-4"></v-divider>

                <v-card-title
                  class="text-h3 text--primary justify-center text-center"
                  >{{ scannedItemDetails.zone_name }}</v-card-title
                >

                <v-card-text class="mx-auto justify-center text-center">
                  <v-chip-group
                    class="mx-auto justify-center text-center"
                    active-class="deep-purple accent-4"
                    column
                  >
                    <v-chip class="mx-auto justify-center text-center">{{
                      scannedItemDetails.service_name
                    }}</v-chip>

                    <!-- <v-chip>7:30PM</v-chip> -->
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <div v-for="item in datosComunas" :key="item.address">
            <h1 style="font-size: 50px">ZONA</h1>
            <h1 style="font-size: 100px">{{ item.zone_name }}</h1>
            <div v-if="dibujando">
              <h1 style="color: red">{{ mensajeZona }}</h1>
            </div>

            <br />

            <h1 style="font-size: 50px">RUTA</h1>
            <h1 style="font-size: 100px">
              {{ item.zone_name + "-" + item.name_ruta }}
            </h1>
            <div v-if="dibujando">
              <h1 style="color: red">{{ mensajeRuta }}</h1>
            </div>
            <br />

            <h2>{{ item.address }}</h2>
            <br />
            <br />
            <v-layout no-gutters align-center justify-center>
              <v-img
                max-height="150"
                max-width="250"
                :src="item.image_customer"
              >
              </v-img>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container fluid :style="backgroundColor" v-else>
      <v-layout
        no-gutters
        align-center
        justify-center
        mr-3
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          color: #fff;
        "
      >
        <v-flex xs4 sm4 lg4 xl4 style="text-align: center">
          <h1 style="font-size: 50px">{{ this.order_id }}</h1>
          <h3>Código escaneado</h3>
          <!--vue-clock /-->
        </v-flex>
        <v-divider vertical style="border-width: 4px !important"></v-divider>
        <v-flex xs12 sm12 lg12 xl12 style="text-align: center">
          <p style="font-size: 30px"></p>
          <h1 style="font-size: 150px">CODIGO INVALIDO</h1>
          <h2></h2>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import firebase from "firebase";
import VueAxios from "vue-axios";
// import VueJsonPretty from 'vue-json-pretty'
// import VueClock from '@dangvanthanh/vue-clock'
import moment from "moment-timezone";
// import { indexForKey } from "@posva/vuefire-core/dist/packages/@posva/vuefire-core/src/rtdb/utils";
// import { threadId } from 'worker_threads';

var ObjectID = require("bson-objectid");

// const fbSetup = require('../firebaseConfig.js')
Vue.use(VueAxios, axios);
export default {
  // components: { VueClock },
  data() {
    return {
      sizeOrder: "",
      selection: "sm",
      sizes: ["sm", "md", "lg", "xl"],
      itemQ: 1,
      dialogIngressType: false,
      sendingRequest: false,
      currentIngressType: {},
      ingressTypesList: [],
      disableInput: false,
      scannedItemDetailsBase: {
        status: 200,
        order_id_task: "",
        idcustomer_task: "",
        thor: false,
        address: "",
        address_details: "",
        zone_id: "",
        zone_name: "",
        name_customer: "",
        image_customer: "",
        id_ruta: "",
        name_ruta: "",
        latitude_task: "",
        longitude_task: "",
        service_id: "test_code",
        // service_name: "Entrega Same Day WOM (demo)",
      },
      scannedItemDetails: {
        status: 200,
        order_id_task: "",
        idcustomer_task: "",
        thor: false,
        address: "",
        address_details: "",
        zone_id: "",
        zone_name: "",
        name_customer: "",
        image_customer: "",
        id_ruta: "",
        name_ruta: "",
        latitude_task: "",
        longitude_task: "",
        service_id: "test_code",
        // service_name: "Entrega Same Day WOM (demo)",
      },
      loader: false,
      currentIngress: {
        items: [],
        currentIngressType: "No Iniciado",
        customer_name: "No Iniciado",
        numRecepcion: "No iniciado",
        creation_date: "No Iniciado",
        name_status: "No Iniciado",
      },
      selectedCustomer: { _id: null, customer_name: null },
      selectedWarehouse: { _id: null, warehouse_name: null },
      selectedRayo: { _id: null, name_rayo: null },
      dialogClientes: false,
      orderIngressStatusList: [],
      materialIngressStatusList: [],
      cluster: "Cluster0",
      baseAPI:
        "https://europe-west1-rayoio.cloudfunctions.net/proxy-test-dev-cluster1-belgica",
      //"https://us-central1-rayoio.cloudfunctions.net/proxy-test-dev-cluster1",
      fecha: moment().format("YYYY-MM-DD - HH:mm"),
      currentIngressStatus: { name_status: null, number_status: null },
      arrayScan: [],
      datosComunas: [],
      arrayComunas: [],
      objetoProceso: [],
      recuperoData: [],
      itemDetails: {},
      timeoutCopy: 1000,
      numeroRecepción: null,
      userScan: "",
      itemId: "",
      order_id: "",
      order_idScan: "",
      mensajeZona: "",
      mensajeRuta: "",
      errorDirección: "",
      mensajeCierre: "",
      latPedido: "",
      longPedido: "",
      dibujando: false,
      snackMongo: false,
      botonFin: false,
      inicioProceso: false,
      dialog: false,
      dialogCancel: false,
      dialogOK: false,
      dialogPedidos: false,
      dialogRecupero: false,
      dialogFinRecepcion: false,
      verPedidos: false,
      verMapa: false,
      snackbarCopy: false,
      clientes: [],
      warehouses: [],
      rayos: [],
      providers: [],
      headersMaterials: [
        {
          value: "barcode",
          text: "Barcode",
        },
        {
          value: "quantity",
          text: "Cantidad",
        },
        {
          value: "ts",
          text: "Escaneado en",
        },
        {
          value: "item_actions",
          text: "Acciones",
        },
      ],
      headers: [
        {
          value: "item_warehouse_name",
          text: "Warehouse",
        },
        {
          value: "name_customer",
          text: "Ciente",
        },

        {
          value: "order_id_task",
          text: "Order Id",
        },
        {
          value: "service_name",
          text: "Service",
        },
        {
          value: "thor",
          text: "En Thor",
        },
        {
          value: "zone_name",
          text: "Zona",
        },
        {
          value: "address",
          text: "Dirección",
        },

        {
          value: "size",
          text: "Size",
        },
        {
          value: "item_actions",
          text: "Acciones",
        },
      ],
      footerProps: { "items-per-page-options": [30, 50, 100] },
      y: "top",
      snackText: "INGRESADO CON ÉXITO",
      timeout: 2000,
      backgroundColor: "background-color: white",

      fuente: {
        "font-size": "150px",
      },
      errorScan: {
        backgroundColor: "#DC353F",
      },
    };
  },
  computed: {
    buscaStatusCierre() {
      if (this.currentIngressType.codeType === 1) {
        return this.orderIngressStatusList.filter(
          (x) => x.number_status === 100
        )[0];
      } else {
        return this.materialIngressStatusList.filter(
          (x) => x.number_status === 1
        )[0];
      }
    },

    buscaStatusInicio() {
      if (this.currentIngressType.codeType === 1) {
        return this.orderIngressStatusList.filter(
          (x) => x.number_status === 99
        )[0];
      } else {
        return this.materialIngressStatusList.filter(
          (x) => x.number_status === 0
        )[0];
      }
    },

    collectionTargetIngress() {
      if (this.currentIngressType.codeType === 1) {
        return "order_ingress";
      } else {
        return "material_ingress";
      }
    },

    mapUrl() {
      const url = "http://maps.googleapis.com/maps/api/staticmap";
      console.log("Latitud", this.latPedido, "Longitud", this.longPedido);

      const params = new URLSearchParams({
        center: `${this.latPedido},${this.longPedido}`,
        zoom: 10,
        size: "300x300",
        maptype: ["coordinate", "roadmap"],
        key: "AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg",
        markers: `${this.latPedido},${this.longPedido}`,

        label: this.order_id,
      });
      return `${url}?${params}`;
    },
    calculaTotales() {
      const that = this;
      let valor = 0;
      for (let i in that.currentIngress.items) {
        if (this.currentIngress.items[i].quantity !== undefined) {
          console.log("go");
          valor += parseInt(this.currentIngress.items[i].quantity);
          //  if(i === that.currentIngress.items.length-1 ) {
          //  }
        }
      }
      return valor;
    },
  },

  methods: {
    // import firebase from "firebase";
    datosUsuario() {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid,
      };
    },
    deleteRemoteAndLocalMaterials(deleteIn) {
      this.loader = true;
      console.log(deleteIn);
      if (
        this.currentIngress.items.filter((x) => x.barcode === deleteIn)[0] !==
          undefined &&
        this.currentIngress.items.filter((x) => x.barcode === deleteIn)[0] !==
          null
      ) {
        this.removeItemMaterials(deleteIn);
      }
    },
    deleteRemoteAndLocal(deleteIn) {
      this.loader = true;
      console.log(deleteIn);
      if (
        this.currentIngress.items.filter(
          (x) => x.order_id_task === deleteIn
        )[0] !== undefined &&
        this.currentIngress.items.filter(
          (x) => x.order_id_task === deleteIn
        )[0] !== null
      ) {
        this.removeItem(deleteIn);
      }
    },
    showDate(xxxx) {
      const zona = moment.tz.guess();
      return moment.tz(xxxx, zona).format("YYYY-MM-DD-HH:mm:ss") + "-" + zona;
    },

    toggleDisableButtonIngressType() {
      if (this.currentIngressType._id === undefined) {
        return true;
      } else {
        return false;
      }
    },

    toggleDisableIniciaIngress() {
      if (
        this.selectedCustomer._id === null ||
        this.selectedWarehouse._id === null ||
        this.selectedRayo._id === null
      ) {
        return false;
      } else {
        return false;
      }
    },
    toggleDisableButtonStart() {
      if (
        this.selectedCustomer._id === null ||
        this.selectedWarehouse._id === null
      ) {
        return true;
      } else {
        return false;
      }
    },

    openLink(link) {
      navigator.clipboard.writeText(link);
      this.snackbarCopy = true;
    },

    async getProviders() {
      const that = this;
      if (!that.loader) {
        that.loader = true;
      }

      that.snackMongo = true;
      that.snackText = "Cargando EPAS...";

      try {
        const respuestaProviders = await axios.get(
          "https://backendservices.rayoapp.com/Providers/providers?rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("EPAS listadas!");

        that.providers = respuestaProviders.data;
        that.snackMongo = true;
        that.snackText = "Lista EPAS OK";
        return true;
      } catch (error) {
        that.snackText = "error";
        that.snackMongo = true;
        console.log(error);
        return false;
      }
    },
    async listaRayos() {
      const that = this;
      if (!that.loader) {
        that.loader = true;
      }
      that.snackMongo = true;
      that.snackText = "Cargando rayos...";
      try {
        const respuestaRayos = await axios.get(
          "https://backendservices.rayoapp.com/Rayos/rayos/reduced?vigencia=true&rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("Rayos listados!");

        that.rayos = respuestaRayos.data.sort(function (a, b) {
          return a.name_rayo.localeCompare(b.name_rayo, "en", {
            numeric: true,
          });
        });

        // that.rayos = respuestaRayos.data;
        that.snackMongo = true;
        that.snackText = "Lista RAYOS OK";
        return true;
      } catch (error) {
        that.snackText = "error";
        that.snackMongo = true;
        console.log(error);
        return false;
      }
    },
    async listarClientes() {
      const that = this;
      if (!that.loader) {
        that.loader = true;
      }
      that.snackMongo = true;
      that.snackText = "Cargando clientes...";
      try {
        const respuestaClientes = await axios.get(
          "https://backendservices.rayoapp.com/Customers/customers?rayo=08a46637120a404a963395dd86986b4f",
          {
            headers: {
              "warehouse.rayoapp.com": "rayo",
            },
          }
        );
        console.log("Clientes listados!");

        that.clientes = respuestaClientes.data;
        that.snackMongo = true;
        that.snackText = "Lista clientes OK";
        return true;
      } catch (error) {
        that.snackText = "error";
        that.snackMongo = true;
        console.log(error);
        return false;
      }
    },

    seleccionClienteWarehouse() {
      this.dialogClientes = true;
    },

    async inicioOperacion() {
      const that = this;
      if (!that.loader) {
        that.loader = true;
      }
      this.botonFin = true;
      this.inicioProceso = true;
      this.verPedidos = true;
      const currentDate = new Date();
      // that.currentIngress.numRecepcion = currentDate.getTime();
      that.currentIngressStatus = this.buscaStatusInicio;
      that.currentIngress.name_rayo = that.selectedRayo.name_rayo;
      that.currentIngress.email_rayo = that.selectedRayo.email_rayo;
      that.currentIngress.id_rayo = that.selectedRayo._id;
      that.currentIngress.id_provider_rayo = that.selectedRayo.provider_rayo;
      if (
        that.providers.filter(
          (x) => x._id === that.selectedRayo.provider_rayo
        )[0] === null ||
        that.providers.filter(
          (x) => x._id === that.selectedRayo.provider_rayo
        )[0] === undefined
      ) {
        that.currentIngress.name_provider_rayo = null;
      } else {
        that.currentIngress.name_provider_rayo = that.providers.filter(
          (x) => x._id === that.selectedRayo.provider_rayo
        )[0].name_provider;
      }
      that.currentIngress.warehouse_id = that.selectedWarehouse._id;
      that.currentIngress.warehouse_name = that.selectedWarehouse.name;
      that.currentIngress.warehouse_id = that.selectedWarehouse._id;
      that.currentIngress.customer_name = that.selectedCustomer.name_customer;
      that.currentIngress.customer_id = that.selectedCustomer._id;
      that.currentIngress.ingress_type_code = that.currentIngressType.codeType;
      that.currentIngress.ingress_type_name = that.currentIngressType.name;
      that.currentIngress.ingress_type_id = that.currentIngressType._id;
      // that.currentIngress.number_status = that.buscaStatusInicio.number_status;
      // that.currentIngress.name_status = that.buscaStatusInicio.name_status;
      // that.currentIngress.status_id = that.buscaStatusInicio._id;

      var data = {
        customer_id: that.currentIngress.customer_id,
        customer_name: that.currentIngress.customer_name,
        id_rayo: that.currentIngress.id_rayo,
        name_rayo: that.currentIngress.name_rayo,
        email_rayo: that.currentIngress.email_rayo,
        id_provider_rayo: that.currentIngress.id_provider_rayo,
        name_provider_rayo: that.currentIngress.name_provider_rayo,
        warehouse_id: that.currentIngress.warehouse_id,
        warehouse_name: that.currentIngress.warehouse_name,
        numRecepcion: that.currentIngress.numRecepcion,
        creation_date: moment().utc(),
        usr: this.$store.state.user.data.email,
        usr_mail: this.$store.state.user.data.email,
        status_id: that.currentIngress._id,
        name_status: that.currentIngress.name_status,
        number_status: that.currentIngress.number_status,
        ingress_type_code: that.currentIngress.ingress_type_code,
        ingress_type_name: that.currentIngress.ingress_type_name,
        ingress_type_id: that.currentIngress.ingress_type_id,

        items: [],

        emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime(),
      };

      // console.log("BODY INICIO OP", data);

      await axios
        .post(
          "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse",
          data
        )
        .then(function (response) {
          console.log(
            "Respuesta Api Creacion: " +
              JSON.stringify(response.data.data.insertedIds[0])
          );

          that.snackText =
            "Recepción iniciada con id " + response.data.data.insertedIds[0];
          that.currentIngress.numRecepcion = response.data.data.insertedIds[0];
          that.snackMongo = true;
          that.loader = false;
          return true;
        })

        .catch(function (error) {
          console.log("ERROR", error);

          that.backgroundColor = "background-color: #DC353F";
          alert("Error en llamada a API de Inicio Operación de recepción");
          that.inicioProceso = false;
          that.verPedidos = false;
          that.snackText = error;
          that.snackMongo = true;
          that.loader = false;
          return false;
        });
    },

    // elimina desde el ingress materials en WMS y local

    async removeItemMaterials(removedItemIn) {
      const that = this;

      if (removedItemIn !== "" && removedItemIn !== " ") {
        var data = JSON.stringify({
          dataSource: that.cluster,
          database: "wms",
          collection: "material_ingress",
          filter: {
            numRecepcion: that.currentIngress.numRecepcion,
          },
          update: {
            // $push: { "items": { item_id: this.itemId } }
            $pull: { items: { barcode: removedItemIn } },
          },
        });

        var config = {
          method: "put",
          url: this.baseAPI + "/updateOne",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        // remueve ok

        axios(config)
          .then(function () {
            that.dialog = false;
            that.snackText = "item material eliminado";
            that.snackMongo = true;
            that.itemId = "";

            // borra local
            const indexDelete = that.currentIngress.items.findIndex(
              (x) => x.barcode === removedItemIn
            );
            that.currentIngress.items.splice(indexDelete, 1);
            // borra local

            that.scannedItemDetails = that.scannedItemDetailsBase;

            that.loader = false;
          })
          .catch(function (error) {
            that.dialog = false;
            console.log(error);
            that.scannedItemDetails = that.scannedItemDetailsBase;

            that.loader = false;
          });
      }
    },

    // elimina desde el ingress en WMS

    async removeItem(removedItemIn) {
      const that = this;

      console.log("lo que recibo eliminar: " + removedItemIn);
      if (removedItemIn !== "") {
        var data = {
          scanCode: removedItemIn,
          _id: that.currentIngress.numRecepcion,
        };

        console.log("Lo que envio a eliminar: " + JSON.stringify(data));

        axios
          .put(
            "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/deleteItembyCodeID",
            data
          )
          .then(function (response) {
            that.dialog = false;
            that.snackText = "item eliminado";
            that.currentIngress.items = response.data.data.items;
            that.snackMongo = true;
            that.itemId = "";

            // borra local
            // const indexDelete = that.currentIngress.items.findIndex(
            //   (x) => x.order_id_task === removedItemIn
            // );
            // that.currentIngress.items.splice(indexDelete, 1);
            // borra local

            that.scannedItemDetails = that.scannedItemDetailsBase;

            that.loader = false;
          })
          .catch(function (error) {
            that.dialog = false;
            console.log(error);
            that.scannedItemDetails = that.scannedItemDetailsBase;

            that.loader = false;
          });
      }
    },

    // GUARDAMOS REGISTRO DE CADA ESCANEO CON SU N° DE RECEPCIÓN.

    async scanItems() {
      const that = this;
      // console.log('code ingrees type :',that.currentIngress.ingress_type_code)
      // that.loader  = true
      that.scannedItemDetails = that.scannedItemDetailsBase;
      that.disableInput = true;

      if (
        that.itemId.trim() !== "" &&
        (that.currentIngress.items.filter(
          (x) => x.order_id_task === that.itemId.trim()
        )[0] === null ||
          that.currentIngress.items.filter(
            (x) => x.order_id_task === that.itemId.trim()
          )[0] === undefined)
      ) {
        let gg = await that.getItemDetailsFromThor();
        that.scannedItemDetails = gg;
        // console.log("ver GG: " + JSON.stringify(gg));
        var data = {
          items: this.currentIngress.items,
          scanCode: this.itemId.trim(),
          _id: this.currentIngress.numRecepcion,
          type: "add",
          emailResponsable: this.datosUsuario().email_user,
          nameResponsable: this.datosUsuario().name_user,
          uidResponsable: this.datosUsuario().uid,
          sistemaResponsable: "warehouse.rayoapp.com",
          epochResponsable: new Date().getTime(),
          size: this.selection,
        };

        console.log("lo que envio a add:" + JSON.stringify(data));

        // si no está en thor debe insertarlo....
        if (gg.thor) {
          //  inserta
          if (gg.idcustomer_task !== that.currentIngress.customer_id) {
            that.snackText =
              "El cliente corporativo no corresponde al order_id escaneado. PEDIDO NO INSERTADO";
            that.snackMongo = true;
            // that.scannedItemDetails = that.scannedItemDetailsBase;
            that.itemId = "";
            that.disableInput = false;
            Vue.nextTick(() => {
              that.$refs.input2.focus();
            });
          } else {
            // inserta ok
            // acá deberíamos hacerla async y que devuelva el true o false...
            axios
              .put(
                "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/byCodeID",
                data
              )
              .then(function (response) {
                // console.log(
                //   "Respuesta de la API :" + JSON.stringify(response.data)
                // );
                // console.log("inicia llamada juan");
                that.dialog = false;
                that.snackText = "¡Datos cargados!";
                that.snackMongo = true;
                that.itemId = "";
                // console.log("termina llamada juan");

                // that.currentIngress.items.push(gg);
                that.currentIngress.items = response.data.data.items;

                that.disableInput = false;
                that.itemId = "";
                Vue.nextTick(() => {
                  that.$refs.input2.focus();
                });

                that.loader = false;
              })
              .catch(function (error) {
                that.dialog = false;
                that.itemId = "";
                console.log(error);
                // that.scannedItemDetails = that.scannedItemDetailsBase;
                that.disableInput = false;
                Vue.nextTick(() => {
                  that.$refs.input2.focus();
                });
                that.loader = false;
              });
          }
        }
        // si está en thor pero no corresponde a cliente no inserta
        else {
          axios
            .put(
              "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/byCodeID",
              data
            )
            .then(function (response) {
              console.log(
                "Respuesta de la API: " + JSON.stringify(response.data)
              );
              that.dialog = false;
              that.snackText = "Datos cargados ---";
              that.snackMongo = true;
              that.itemId = "";
              // console.log(
              //   "Respuesta add Item: " + JSON.stringify(response.data)
              // );

              // that.currentIngress.items.push(gg);
              that.currentIngress.items = response.data.data.items;

              // that.scannedItemDetails = that.scannedItemDetailsBase;
              that.disableInput = false;
              Vue.nextTick(() => {
                that.$refs.input2.focus();
              });
              that.loader = false;
            })
            .catch(function (error) {
              that.dialog = false;
              that.itemId = "";
              console.log(error);
              // that.scannedItemDetails = that.scannedItemDetailsBase;
              that.disableInput = false;
              Vue.nextTick(() => {
                that.$refs.input2.focus();
              });
              that.loader = false;
            });
        }
      } else {
        that.snackText = "Ya escaneaste este Item";
        that.itemId = "";
        that.snackMongo = true;
        that.disableInput = false;
        Vue.nextTick(() => {
          that.$refs.input2.focus();
        });
        that.loader = false;
      }
    },

    async scanItemsMaterials() {
      const that = this;
      // that.loader  = true
      that.scannedItemDetails = that.scannedItemDetailsBase;
      that.disableInput = true;

      if (
        that.itemId.trim() !== "" &&
        that.itemQ > 0 &&
        (that.currentIngress.items.filter(
          (x) => x.order_id_task === that.itemId.trim()
        )[0] === null ||
          that.currentIngress.items.filter(
            (x) => x.order_id_task === that.itemId.trim()
          )[0] === undefined)
      ) {
        // inserta stock materiales

        that.scannedItemDetails = {
          barcode: that.itemId.trim(),
          ts: moment().utc(),
          quantity: parseInt(that.itemQ),
        };

        var data = JSON.stringify({
          dataSource: that.cluster,
          database: "wms",
          collection: "material_ingress",
          filter: {
            numRecepcion: that.currentIngress.numRecepcion,
          },
          update: {
            // $push: { "items": { item_id: this.itemId } }
            $push: { items: await that.scannedItemDetails },
          },
        });
        var config = {
          method: "put",
          url: this.baseAPI + "/updateOne",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        // inserta ok

        axios(config)
          .then(function () {
            console.log("inicia llamada juan");
            that.dialog = false;
            that.snackText = "Datos cargados -";
            that.snackMongo = true;
            that.itemId = "";
            that.itemQ = 1;
            console.log("termina llamada juan");
            that.currentIngress.items.push(that.scannedItemDetails);

            // that.scannedItemDetails = that.scannedItemDetailsBase;
            that.disableInput = false;
            Vue.nextTick(() => {
              that.$refs.input2.focus();
            });
            that.loader = false;
          })
          .catch(function (error) {
            that.dialog = false;
            console.log(error);
            // that.scannedItemDetails = that.scannedItemDetailsBase;
            that.disableInput = false;
            that.itemId = "";
            that.itemQ = 1;
            Vue.nextTick(() => {
              that.$refs.input2.focus();
            });
            that.loader = false;
          });
      } else {
        that.snackText =
          "Ya escaneaste este Item o seleccionaste una cantidad incorrecta";
        that.snackMongo = true;
        that.disableInput = false;
        that.itemId = "";
        Vue.nextTick(() => {
          that.$refs.input2.focus();
        });
        that.loader = false;
      }
    },

    // ff scan item

    // CERRAMOS RECEPCIÓN.

    async finOperacion() {
      this.dialog = false;
      this.sendingRequest = true;
      if (!this.loader) {
        this.loader = true;
      }

      const that = this;
      let data = {
        _id: this.currentIngress.numRecepcion,
        items: this.currentIngress.items,
        evidences: { note: null, photo: null, email: null },
        emailResponsable: this.datosUsuario().email_user,
        nameResponsable: this.datosUsuario().name_user,
        uidResponsable: this.datosUsuario().uid,
        sistemaResponsable: "warehouse.rayoapp.com",
        epochResponsable: new Date().getTime(),
      };

      console.log("Lo que envio fin Operacion: " + JSON.stringify(data));

      await axios
        .post(
          "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/CloseReceptionbyID",
          data
        )
        .then(function (response) {
          // console.log(
          //   "Lo que recibo Fin recepción:" + JSON.stringify(response.data)
          // );
          that.snackText = "Recepción de órdenes finalizada!";
          that.refreshPage();
          that.snackMongo = true;
          that.botonFin = false;
          that.loader = false;
          that.dialogFinRecepcion = true;
          return true;
        })
        .catch(function (error) {
          console.log(error);
          that.botonFin = false;
          that.inicioProceso = false;
          that.snackText = "Reintento de cierre de recepción";
          that.snackMongo = true;
          console.log("Reintento de cierre de recepción");
          axios
            .post(
              "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/CloseReceptionbyID",
              data
            )
            .then(function (response) {
              //console.log("Lo que recibo Fin recepción:" + response.data);
              that.snackText = "Recepción de órdenes finalizada.";
              that.snackMongo = true;
              that.botonFin = false;
              that.inicioProceso = false;
              that.loader = false;
              that.refreshPage();

              // finalización exitosa realizamos envio a backend de manifiesto
              // that.envioBackendManifiesto(that.currentIngress);
              // fin envio

              return true;
            })
            .catch(function (error) {
              console.log(error);
              that.botonFin = false;
              that.inicioProceso = false;
              that.loader = false;
              return false;
            });
        });
      this.sendingRequest = false;
    },

    async cancelRecepcion() {
      this.dialogCancel = false;
      if (!this.loader) {
        this.loader = true;
      }
      const that = this;

      await axios
        .delete(
          "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse",
          {
            data: {
              _id: this.currentIngress.numRecepcion,
              emailResponsable: this.datosUsuario().email_user,
              nameResponsable: this.datosUsuario().name_user,
              uidResponsable: this.datosUsuario().uid,
              sistemaResponsable: "warehouse.rayoapp.com",
              epochResponsable: new Date().getTime(),
            },
          }
        )
        .then(function (response) {
          console.log(
            "Lo que recibo Cancel Recepcion:" + JSON.stringify(response.data)
          );
          that.snackText = "Recepción de órdenes Cancelada!";
          that.refreshPage();
          that.snackMongo = true;
          that.botonFin = false;
          that.loader = false;
          return true;
        })
        .catch(function (error) {
          console.log(error);
          that.botonFin = false;
          that.inicioProceso = false;
          that.snackText = "Reintento de Cancelación de recepción";
          that.snackMongo = true;
          console.log("Reintento de Cancelación de recepción");
          axios
            .delete(
              "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse",
              {
                data: {
                  _id: this.currentIngress.numRecepcion,
                  emailResponsable: this.datosUsuario().email_user,
                  nameResponsable: this.datosUsuario().name_user,
                  uidResponsable: this.datosUsuario().uid,
                  sistemaResponsable: "warehouse.rayoapp.com",
                  epochResponsable: new Date().getTime(),
                },
              }
            )
            .then(function (response) {
              //console.log("Lo que recibo Fin recepción:" + response.data);
              that.snackText = "Recepción de órdenes Cancelada.";
              that.snackMongo = true;
              that.botonFin = false;
              that.inicioProceso = false;
              that.loader = false;
              that.refreshPage();

              // finalización exitosa realizamos envio a backend de manifiesto
              // that.envioBackendManifiesto(that.currentIngress);
              // fin envio

              return true;
            })
            .catch(function (error) {
              console.log(error);
              that.botonFin = false;
              that.inicioProceso = false;
              that.loader = false;
              return false;
            });
        });
    },

    async envioBackendManifiesto(objetoEnvio) {
      const that = this;
      if (!that.loader) {
        that.loader = true;
      }
      var config = {
        method: "post",
        url: "https://us-central1-rayoio.cloudfunctions.net/manifiesto",
        headers: {
          "Content-Type": "application/json",
        },
        data: objetoEnvio,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          that.snackText = "envio a backend manifesto exitoso";
          that.snackMongo = true;
          that.loader = false;
          return true;
        })
        .catch(function (error) {
          console.log(error);
          that.snackText = "error en envío backend manifesto exitoso";
          that.snackMongo = true;
          that.loader = false;
          return false;
        });
    },

    async getWarehouses() {
      var that = this;
      if (!that.loader) {
        that.loader = true;
      }
      that.snackMongo = true;
      that.snackText = "Cargando Warehouses...";

      // var data = JSON.stringify({
      //   dataSource: this.cluster,
      //   database: "wms",
      //   collection: "warehouse",
      // });

      // var config = {
      //   method: "post",
      //   url: this.baseAPI + "/find",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios
        .get("https://us-central1-rayoio.cloudfunctions.net/warehouse")
        .then(function (response) {
          that.snackMongo = true;
          that.snackText = "Lista de warehouses OK";
          console.log("ok warehouses");
          that.warehouses = response.data.data;
          return true;
        })

        .catch(function (error) {
          console.log("fallo warehouses");
          console.log("ERROR", error);
          that.snackText = error;
          that.snackMongo = true;
          return false;
        });

      return await resultado;
    },

    async getItemDetailsFromThor() {
      var that = this;
      // if (!that.loader) {
      //   that.loader = true;
      // }

      // var data = JSON.stringify({
      //   dataSource: this.cluster,
      //   database: "RAYO-IO",
      //   collection: "items",
      //   filter: { code: that.itemId },
      //   sort: {},
      //   limit: 1000,
      // });

      // var config = {
      //   method: "post",
      //   url: this.baseAPI + "/find",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // };

      // https://southamerica-east1-rayo-api.cloudfunctions.net/funcionesRayo/funcionesRayo/ConsultaZona/
      // "orderID" : "MGPSF205-13725165-52"
      var config = {
        method: "post",
        url: "https://southamerica-east1-rayo-api.cloudfunctions.net/funcionesRayo/funcionesRayo/ConsultaZona/",
        headers: {
          "Content-Type": "application/json",
        },
        data: { orderID: that.itemId.trim() },
      };

      // console.log('BODY GET STATUSES', config.data)
      const resultado = await axios(config)
        .then(function (response) {
          // console.log(response)

          // else {
          return response.data;
          //  }
        })

        .catch(function (error) {
          if (!error.response.data.thor) {
            console.log("Item desconocido");
            that.snackMongo = true;
            that.snackText = "Item desconocido. Se insertará igualmente";
            Vue.nextTick(() => {
              that.$refs.input2.focus();
            });
            return {
              image_customer: "https://rayoapp.com/img/2020/logo_azul.png",
              order_id_task: that.itemId,
              thor: false,
              zone_id: "63a307041a03c247a4bbf02f",
              zone_name: "Sin Zona",
              idcustomer_task: "5f0c95b1495f530ee0dbe9ff",
              name_customer: "Sin Cliente",
            };
          } else {
            console.log("ERROR:", JSON.stringify(error));
            that.snackText = error;
            that.snackMongo = true;
            Vue.nextTick(() => {
              that.$refs.input2.focus();
            });
          }
        });

      return await resultado;
    },

    async getIngressTypes() {
      var that = this;
      if (!that.loader) {
        that.loader = true;
      }
      // var data = JSON.stringify({
      //   dataSource: this.cluster,
      //   database: "wms",
      //   collection: "ingress_type",
      //   filter: {},
      //   sort: {},
      //   limit: 1000,
      // });

      // var config = {
      //   method: "post",
      //   url: this.baseAPI + "/find",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: data,
      // };

      try {
        const response = await axios.get(
          "https://us-central1-rayoio.cloudfunctions.net/typeIngress"
        );
        console.log("ok ingress types");
        that.ingressTypesList = response.data.data;
        that.snackText = "Carga de  tipos de ingreso OK";
        that.snackMongo = true;
        return true;
      } catch (error) {
        console.log("ERROR", error);
        console.log("fallo carga tipos de ingreso");
        that.snackText = error;
        that.snackMongo = true;
        return false;
      }
    },

    async getMaterialIngressStatus() {
      var that = this;
      if (!that.loader) {
        that.loader = true;
      }
      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "wms",
        collection: "material_status",
        filter: {},
        sort: {},
        limit: 1000,
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios(config);
        console.log("ok ingress status");
        that.materialIngressStatusList = response.data.documents;
        that.snackText = "Carga de  Statuses materials OK";
        that.snackMongo = true;
        return true;
      } catch (error) {
        console.log("ERROR", error);
        console.log("fallo ingress status");
        that.snackText = error;
        that.snackMongo = true;
        return false;
      }
    },

    async getOrderIngressStatus() {
      var that = this;
      if (!that.loader) {
        that.loader = true;
      }
      var data = JSON.stringify({
        dataSource: this.cluster,
        database: "fleet_manager",
        collection: "status",
        filter: {},
        sort: {},
        limit: 1000,
      });

      var config = {
        method: "post",
        url: this.baseAPI + "/find",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios(config);
        console.log("ok ingress status");
        that.orderIngressStatusList = response.data.documents;
        that.snackText = "Carga de  Statuses OK";
        that.snackMongo = true;
        return true;
      } catch (error) {
        console.log("ERROR", error);
        console.log("fallo ingress status");
        that.snackText = error;
        that.snackMongo = true;
        return false;
      }
    },

    refreshPage() {
      this.currentIngressType = "";
      this.selectedWarehouse = "";
      this.selectedCustomer = "";
      this.selectedRayo = "";
      this.currentIngress.items = [];
      this.inicioProceso = false;
      this.scannedItemDetails = this.scannedItemDetailsBase;
    },

    getCleaDate(date) {
      if (date) {
        let cleanDate = date.replace("T", " - ");
        cleanDate = cleanDate.substr(0, 18);
        return cleanDate;
      } else {
        return "--";
      }
    },

    async refreshItems() {
      this.loader = true;

      if (await this.operacionRecupero()) {
        this.dialogPedidos = true;
        this.dialogRecupero = false;
        this.loader = false;
      } else {
        this.loader = false;
        this.snackText = "error al recuperar el listad comunícate con IT";
        this.snackMongo = true;
      }
    },

    async operacionRecupero() {
      var that = this;
      if (!that.loader) {
        that.loader = true;
      }
      // console.log(
      //   "Lo que envio a api recupero: " +
      //     JSON.stringify({ usr_mail: that.$store.state.user.data.email })
      // );

      try {
        const response = await axios.post(
          "https://us-central1-rayoio.cloudfunctions.net/receptionWarehouse/searchbyEmail",
          { usr_mail: that.$store.state.user.data.email }
        );

        // console.log("Lo que recibo Recupero:" + JSON.stringify(response.data));

        if (response.data.status == 200) {
          that.currentIngress.numRecepcion = response.data.data._id;
          that.currentIngress.warehouse_id = response.data.data.warehouse_id;
          that.currentIngress.warehouse_name =
            response.data.data.warehouse_name;
          that.currentIngress.customer_name = response.data.data.customer_name;
          that.currentIngress.customer_id = response.data.data.customer_id;
          that.currentIngress.ingress_type_code =
            response.data.data.ingress_type_code;
          that.currentIngress.items = response.data.data.items;
          that.currentIngress.name_status = response.data.data.status;
          that.currentIngress.date_creation = response.data.data.date_creation;
          that.currentIngress.ingress_type_name =
            response.data.data.ingress_type_name;

          this.botonFin = true;
          this.inicioProceso = true;
          this.verPedidos = true;
          that.dialogRecupero = true;
        }

        return true;
      } catch (error) {
        console.log("ERROR", error);
        that.snackText = error;
        that.snackMongo = true;
        return false;
      }
    },

    cerramos() {
      this.inicioProceso = false;
      this.arrayComunas = [];
      this.arrayScan = [];
      this.order_id = "";
      this.order_idScan = "";
      this.orderID = "";
      this.dialogOK = false;
      this.datosComunas = [];
      this.botonFin = false;

      this.recuperoData = [];
      this.verPedidos = false;
      this.verMapa = false;
    },

    arrayZonas() {
      this.arrayComunas.push(this.datosComunas);
      console.log("ZONAS", this.arrayComunas);
    },

    resumenItems(data) {
      let x;
      let existeThor = 0;
      let NoExisteThor = 0;
      let resumen = {};
      for (x in data) {
        if (data[x].thor == true) existeThor++;
        else if (data[x].thor == false) NoExisteThor++;
      }
      resumen.existeThor = existeThor;
      resumen.NoExisteThor = NoExisteThor;
      return resumen;
    },

    toggleDisableButtonFin() {
      if (this.currentIngress.items.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    const finalResult = [
      await this.listarClientes(),
      await this.getWarehouses(),
      //  await this.getOrderIngressStatus(),
      //  await this.getMaterialIngressStatus(),

      await this.getProviders(),
      await this.getIngressTypes(),
      await this.listaRayos(),
      await this.operacionRecupero(),
    ];

    //  console.log(finalResult)
    let checker = (arr) => finalResult.every((v) => v === true);
    if (checker) {
      this.loader = false;
    }
  },
};
</script>

<style>
.myStyle {
  font-size: 25px;
}
.text-white input {
  color: #311eee !important;
}

.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04aa6d;
  padding: 14px 28px;
  font-size: 50px;
  cursor: pointer;
  text-align: center;
}

.block:hover {
  background-color: #ddd;
  color: black;
}
</style>
